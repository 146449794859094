import type { SortOrder, Subscriber } from './types'
import type { Pageable } from '~/shared/api'
import {
  createEvent,
  createEffect,
  createStore,
  restore,
  guard,
  combine,
  sample,
} from 'effector'
import { debounce } from 'patronum/debounce'
import { persist } from 'effector-storage/local'
import { getPage, iterationRequest } from './api'

export const setPage = createEvent<number>()
export const setCount = createEvent<number>()
export const setSortBy = createEvent<string>()
export const setSortOrder = createEvent<SortOrder>()
export const getSubscribers = createEvent<void>()
export const resetSubscribers = createEvent<void>()
export const resetSubscribers2 = createEvent<void>()

export const setFilterBatchName = createEvent<number[]>()

export const markSoldMultiple = createEvent<Subscriber>()
export const markSoldAllSubscribers = createEvent()

export const markSubscribersUnSold = createEvent()
export const markSubscribersSold = createEvent()

export const filterUnselectedItems = createEvent<{ current: Set<number> }>()
export const filterSelectedItems = createEvent<{ current: Set<number> }>()

export const getSubscribersDebounced = debounce({
  source: getSubscribers,
  timeout: 0,
})

export const $page = restore(setPage, 0).reset(resetSubscribers)
export const $count = restore(setCount, 10).reset(resetSubscribers)
export const $sortBy = restore(setSortBy, 'id').reset(resetSubscribers)
export const $sortOrder = restore(setSortOrder, 'asc').reset(resetSubscribers)
export const $filterBatchName = restore(setFilterBatchName, []).reset(
  resetSubscribers
)
export const $pageOptions = combine(
  $page,
  $count,
  $sortBy,
  $sortOrder,
  $filterBatchName,
  (page, count, sortBy, sortOrder, filterBatchName) => ({
    page,
    count,
    sortBy,
    sortOrder,
    filterBatchName,
  })
)

export const $subscribers = createStore<Subscriber[] | null>(null)
export const $pageProps = createStore<Omit<Pageable, 'content'> | null>(null)
export const $mayRequest = createStore(false)

persist({
  store: $mayRequest,
  keyPrefix: 'partners/',
  key: 'authenticated',
})

export const getSubscribersFx = createEffect(getPage)
export const $loading = getSubscribersFx.pending

// Set "markSold" param for Subscribers
$subscribers
  .on(getSubscribersFx.doneData, (_, page) =>
    page.content ? page.content.map((c) => ({ ...c, markSold: c.sold })) : null
  )
  .reset(resetSubscribers)
  .reset(resetSubscribers2)

// Mark Subscribers Multiple
$subscribers.on(markSoldMultiple, (subscribers, subscriber) =>
  subscribers?.map((sub) =>
    sub.id === subscriber.id ? { ...sub, markSold: !sub.markSold } : sub
  )
)
// Mark All Subscribers (in SelectedAll Mode)
$subscribers.on(markSoldAllSubscribers, (subscribers) =>
  subscribers?.map((sub) =>
    !sub.sold ? { ...sub, markSold: !sub.markSold } : sub
  )
)

// Save marked subscribers after pagination actions (For SelectedAll mode and UnSelectedAll Mode)
$subscribers
  .on(filterUnselectedItems, (subscribers, { current }) =>
    subscribers?.map((sub) =>
      current.has(sub.id) ? { ...sub, markSold: false } : sub
    )
  )
  .on(filterSelectedItems, (subscribers, { current }) =>
    subscribers?.map((sub) =>
      current.has(sub.id) ? { ...sub, markSold: true } : sub
    )
  )

// Reset "markSold" param after selectAll toggle
$subscribers
  .on(markSubscribersUnSold, (subscribers, _) =>
    subscribers?.map((sub) => (!sub.sold ? { ...sub, markSold: false } : sub))
  )
  .on(markSubscribersSold, (subscribers, _) =>
    subscribers?.map((sub) => (!sub.sold ? { ...sub, markSold: true } : sub))
  )

// eslint-disable-next-line @typescript-eslint/no-unused-vars
$pageProps.on(getSubscribersFx.doneData, (_, { content, ...props }) => props)

guard({
  source: $pageOptions,
  filter: $mayRequest,
  target: getSubscribers,
})

guard({
  clock: getSubscribersDebounced,
  source: $pageOptions,
  filter: $mayRequest,
  target: getSubscribersFx,
})

//
// Get pages for mark sold All Subscribers
//

export const getSubscribersForMarkSoldFx = createEffect(iterationRequest)

export const startIteration = createEvent<number>()
export const setLoadingIterationProcess = createEvent<boolean>()
export const oneRequestResponse = createEvent<Pageable<Subscriber>>()
export const resetRoundRequestCount = createEvent()
export const resetIdsForSubmitInSelectAllMode = createEvent()
export const setToastForWaitingRequests = createEvent()

const filterUnChecked = createEvent<{ current: Set<number> }>()
export const startFilteredList = createEvent()

export const markSold = createEvent<number[]>()

export const $unCheckedIdsList = createStore<{ current: Set<number> }>({
  current: new Set(),
})

export const $roundRequestCount = createStore<number>(0).reset(
  resetRoundRequestCount
)

export const $loadingIterationProcess = restore(
  setLoadingIterationProcess,
  false
)

export const $startPage = createStore(0)
export const $fixedCount = createStore(100)

export const $pageOptionsForIteration = combine(
  $startPage,
  $fixedCount,
  $sortBy,
  $sortOrder,
  $filterBatchName,
  (page, count, sortBy, sortOrder, filterBatchName) => ({
    page,
    count,
    sortBy,
    sortOrder,
    filterBatchName,
  })
)

export const $idsForSubmitInSelectAllMode = createStore<number[] | any[]>([])
  .on(resetIdsForSubmitInSelectAllMode, () => [])
  .on(oneRequestResponse, (current, page) => {
    page?.content?.forEach((subscriber) => {
      !subscriber.sold && current.push(subscriber.id)
    })
    return current
  })
  .on(filterUnChecked, (ids, { current: currentUnchecked }) => {
    const filteredListIds = ids.filter(
      (id) => !Array.from(currentUnchecked).includes(id)
    )

    const newIds = []
    for (let i = 0; i < filteredListIds.length; i += 100) {
      newIds.push(filteredListIds.slice(i, i + 100))
    }
    return newIds
  })

sample({
  clock: startIteration,
  source: $pageOptionsForIteration,
  fn: (options, roundRequestCount) => {
    return { options, roundRequestCount: roundRequestCount }
  },
  target: getSubscribersForMarkSoldFx,
})

guard({
  clock: getSubscribersForMarkSoldFx,
  source: getSubscribersForMarkSoldFx,
  filter: (d) => d.roundRequestCount > 10,
  target: setToastForWaitingRequests,
})

guard({
  clock: startFilteredList,
  source: $unCheckedIdsList,
  filter: $mayRequest,
  target: filterUnChecked,
})

guard({
  clock: filterUnChecked,
  source: $idsForSubmitInSelectAllMode,
  filter: $idsForSubmitInSelectAllMode.map((ids) => ids.length !== 0),
  target: markSold,
})
