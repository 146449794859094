import type { FC } from 'react'
import React, { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useStore } from 'effector-react'
import { userModel } from '~/entities/user'
import LoginPage from '~/pages/login'

const HomePage = lazy(() => import('./home'))

export const Routing: FC = () => {
  const isAuthenticated = useStore(userModel.$isAuthenticated)

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    <Switch>
      <Route path='/home' component={HomePage} />
      <Route exact path='/' render={() => <Redirect to='/home' />} />
    </Switch>
  )
}
