import { variant } from '@effector/reflect'
import { combine } from 'effector'
import { SharedMessageError, SharedProgress } from '~/shared'
import {
  $bothIsEmpty,
  $isEmptyEmail,
  $isEmptyPassword,
  $isSignining,
  $wrongCredentialsError,
  $authRequestError,
} from '../model'

const Err = (msg: string) => () => SharedMessageError(msg)

export const ErrorMessageValue = variant({
  source: combine(
    {
      isSignining: $isSignining,
      isEmptyEmail: $isEmptyEmail,
      isEmptyPassword: $isEmptyPassword,
      bothIsEmpty: $bothIsEmpty,
      wrongCredentials: $wrongCredentialsError,
      authRequest: $authRequestError,
    },
    ({
      isSignining,
      isEmptyEmail,
      isEmptyPassword,
      bothIsEmpty,
      wrongCredentials,
      authRequest,
    }) => {
      if (isSignining) return 'signining'
      if (isEmptyEmail) return 'emptyEmail'
      if (isEmptyPassword) return 'emptyPassword'
      if (bothIsEmpty) return 'bothEmpty'
      if (wrongCredentials) return 'wrongCredentials'
      if (authRequest) return 'requestFailed'
      return 'ready'
    }
  ),
  cases: {
    signining: SharedProgress,
    bothEmpty: Err('Please fill your email and password'),
    emptyEmail: Err('Please fill your email'),
    emptyPassword: Err('Please fill your password'),
    wrongCredentials: Err('Invalid credentials'),
    requestFailed: Err('Auth request failed'),
    ready: () => null,
  },
})
