import type { LoginRequest } from '~/entities/user'
import {
  createEvent,
  createEffect,
  createStore,
  forward,
  guard,
} from 'effector'
import { persist } from 'effector-storage/local'
import { userModel } from '~/entities/user'

const HAS_CREDENTIAL_API = !!(
  navigator.credentials && navigator.credentials.preventSilentAccess
)

export const tryAutoLogin = createEvent<void>()

export const getCredentialsFx = createEffect<void, LoginRequest>(() =>
  HAS_CREDENTIAL_API
    ? navigator.credentials.get({ password: true }).then((credentials) =>
        credentials && 'password' in credentials
          ? {
              username: credentials.id,
              password: credentials.password || '',
            }
          : Promise.reject(new Error('No credentials'))
      )
    : Promise.reject(new Error('Credentials API is not supported'))
)

export const storeCredentialsFx = createEffect<LoginRequest, Credential>(
  ({ username, password }) =>
    HAS_CREDENTIAL_API
      ? navigator.credentials
          .create({ password: { id: username, password } })
          .then((credentials) =>
            credentials
              ? navigator.credentials.store(credentials)
              : Promise.reject(new Error('Cannot create credentials'))
          )
      : Promise.reject(new Error('Credentials API is not supported'))
)

export const resetCredentialsFx = createEffect(() =>
  HAS_CREDENTIAL_API
    ? navigator.credentials.preventSilentAccess()
    : Promise.reject(new Error('Credentials API is not supported'))
)

export const changeRememberMe = createEvent()

export const $rememberMe = createStore(true).on(
  changeRememberMe,
  (state) => !state
)

persist({
  store: $rememberMe,
  keyPrefix: 'partners/',
  key: 'remember',
})

guard({
  clock: userModel.loginFx.done,
  source: userModel.loginFx,
  filter: $rememberMe,
  target: storeCredentialsFx,
})

forward({
  from: userModel.logoutFx,
  to: resetCredentialsFx,
})

guard({
  source: tryAutoLogin,
  filter: $rememberMe,
  target: getCredentialsFx,
})

forward({
  from: getCredentialsFx.doneData,
  to: userModel.login,
})
