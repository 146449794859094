import {
  combine,
  createEvent,
  createStore,
  forward,
  restore,
  guard,
  split,
} from 'effector'
import { userModel } from '~/entities/user'
import { subscriberModel } from '~/entities/subscriber'
import { sharedModel } from '~/shared'

export const login = createEvent<void>()
export const logout = createEvent<void>()

export const changeUsername = createEvent<string>()
export const $username = restore(changeUsername, '')

export const changePassword = createEvent<string>()
export const $password = restore(changePassword, '')

export const $isEmptyEmail = $username.map((username) => !username)
export const $isEmptyPassword = $password.map((password) => !password)
export const $bothIsEmpty = combine(
  $username,
  $password,
  (username, password) => !username && !password
)

guard({
  clock: login,
  source: { username: $username, password: $password },
  filter: ({ username, password }) => Boolean(username && password),
  target: userModel.login,
})

forward({
  from: logout,
  to: [userModel.logout, subscriberModel.resetSubscribers],
})

forward({
  from: sharedModel.unauthorizedResponse,
  to: logout,
})

//
// Validation and errors
//

export const $isSignining = userModel.loginFx.pending
export const $isSignouting = userModel.logoutFx.pending
export const $shouldShowError = createStore(false).on(login, () => true)

const setCredentialsError = createEvent<Error>()
const setAuthRequestError = createEvent<Error>()

export const $wrongCredentialsError = createStore(false)
  .on(setCredentialsError, () => true)
  .reset(changePassword, changeUsername)

export const $authRequestError = createStore<Error | null>(null)
  .on(setAuthRequestError, (_, error) => error)
  .reset(changePassword, changeUsername)

split({
  source: userModel.loginFx.failData,
  match: (error: any) => (error?.response?.status ? 'credentials' : 'request'),
  cases: {
    credentials: setCredentialsError,
    request: setAuthRequestError,
  },
})

logout.watch(() => {
  setTimeout(() => {
    const href = window.location.href.replace(window.location.search, '')
    window.history.replaceState({}, document.title, href)
  }, 0)
})
