import type { FC } from 'react'
import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { SharedSpinner } from '~/shared'

// because of syntax ambiguities we have to use `extends` clause here
// eslint-disable-next-line @typescript-eslint/ban-types
export const withRouter = <T extends {}>(Component: FC<T>): FC<T> => {
  const Wrapper = (props: T) => (
    <BrowserRouter basename={import.meta.env.BASE_URL}>
      <Suspense fallback={<SharedSpinner />}>
        <Component {...props} />
      </Suspense>
    </BrowserRouter>
  )
  Wrapper.displayName = `withRouter(${
    Component.displayName || Component.name || 'Component'
  })`
  return Wrapper
}
