import type { SortOrder, Subscriber } from '../types'
import type { CommonResponse, Pageable } from '~/shared/api'
import { ky } from '~/shared/api'
import {
  oneRequestResponse,
  setLoadingIterationProcess,
  startFilteredList,
} from '~/entities/subscriber/model'

export const apiBase = 'distributors/subscribers'

export const getPage = ({
  page,
  count,
  sortBy,
  sortOrder,
  filterBatchName,
}: {
  page: number
  count: number
  sortBy: string
  sortOrder: SortOrder
  filterBatchName: number[]
}): Promise<Pageable<Subscriber>> => {
  return ky
    .get(
      `${apiBase}?page=${page}&count=${count}&sort-by=${
        sortBy || ''
      }&sort-order=${sortOrder || ''}&batch-ids=${filterBatchName || ''}`
    )
    .json<CommonResponse<Pageable<Subscriber>>>()
    .then((res) => res.payload)
}

export interface RequestProps {
  roundRequestCount: number
  options: {
    page: number
    count: number
    sortBy: string
    sortOrder: SortOrder
    filterBatchName: number[]
  }
}

export const iterationRequest = (params: RequestProps) => {
  setLoadingIterationProcess(true)
  if (params.roundRequestCount >= 0) {
    getPage({ ...params.options, page: params.roundRequestCount })
      .then((res) => {
        oneRequestResponse(res)
      })
      .finally(() =>
        iterationRequest({
          ...params,
          roundRequestCount: params.roundRequestCount - 1,
        })
      )
  } else {
    setLoadingIterationProcess(false)
    startFilteredList()
  }
}
