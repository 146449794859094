import { ky } from '~/shared/api'

export type LoginRequest = {
  username: string
  password: string
}

export const postLogin = ({
  username,
  password,
}: LoginRequest): Promise<void> => {
  const body = new URLSearchParams()
  body.set('username', username)
  body.set('password', password)
  return ky.post(`auth`, { body }).then(() => undefined)
}

export const postLogout = (): Promise<void> =>
  ky.post(`logout`).then(() => undefined)
