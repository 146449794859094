import type { FC } from 'react'
import React from 'react'
import { Image, Stack } from '@chakra-ui/react'
import {
  authFeatureModel,
  AuthFeatureUsernameInput,
  AuthFeaturePasswordInput,
  AuthFeatureLoginButton,
  AuthFeatureErrorMessageValue,
} from '~/features/auth'
import { AutoLoginFeatureRememberMeCheckbox } from '~/features/auto-login'
import { useStore } from 'effector-react'

export const LoginForm: FC = () => {
  const errorVisible = useStore(authFeatureModel.$shouldShowError)

  return (
    <Stack p='10px 20px 20px 20px' spacing={5}>
      <Image
        mr='auto'
        ml='auto'
        h='auto'
        w='150px'
        src={`${import.meta.env.BASE_URL}img/logo.png`}
        alt='logo'
        objectFit='cover'
      />

      <AuthFeatureUsernameInput />
      <AuthFeaturePasswordInput />
      <AutoLoginFeatureRememberMeCheckbox />
      <AuthFeatureLoginButton />

      {errorVisible ? <AuthFeatureErrorMessageValue /> : null}
    </Stack>
  )
}
