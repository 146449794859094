import type { ChangeEvent } from 'react'
import React from 'react'
import { reflect } from '@effector/reflect'
import { AtSignIcon } from '@chakra-ui/icons'
import { InputBase } from './InputBase'
import { $username, changeUsername } from '../model'

const inputChanged = (event: ChangeEvent<HTMLInputElement>) =>
  event.target.value

export const UsernameInput = reflect({
  view: InputBase,
  bind: {
    value: $username,
    onChange: changeUsername.prepend(inputChanged),
    placeholder: 'Email Address',
    icon: <AtSignIcon color='gray.500' />,
    type: 'text',
    autocomplete: 'username',
  },
})
