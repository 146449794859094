import type { LoginRequest } from './api'
import {
  createEvent,
  createEffect,
  createStore,
  forward,
  guard,
} from 'effector'
import { persist } from 'effector-storage/local'
import { postLogin, postLogout } from './api'

export const login = createEvent<LoginRequest>()
export const logout = createEvent<void>()

export const loginFx = createEffect(postLogin)
export const logoutFx = createEffect(postLogout)

export const $isAuthenticated = createStore(false)
  .on(loginFx.done, () => true)
  .on(logoutFx, () => false)

persist({
  store: $isAuthenticated,
  keyPrefix: 'partners/',
  key: 'authenticated',
})

guard({
  source: login,
  filter: loginFx.pending.map((pending) => !pending),
  target: loginFx,
})

forward({
  from: logout,
  to: logoutFx,
})
