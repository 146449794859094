import kylib from 'ky'
import { unauthorizedResponse } from '../model'

export type Status = 'OK' | 'ERROR'

export type CommonResponse<T = void> = {
  errorCode?: string
  message?: string
  payload: T
  status: Status
}

export type Pageable<T = void> = {
  content: T[]
  first: boolean
  last: boolean
  number: number
  size: number
  totalElements: number
  totalPages: number
}

const getCookie = (name: string): string | undefined => {
  const cookies = document.cookie.split('; ')
  const cookie = cookies.find((row) => row.startsWith(name + '='))
  if (cookie) {
    return cookie.split('=')[1]
  }
}

export const ky = kylib.create({
  prefixUrl: `${import.meta.env.BASE_URL}api/`,
  hooks: {
    beforeRequest: [
      (request) => {
        const token = getCookie('XSRF-TOKEN')
        if (token) {
          request.headers.set('X-XSRF-TOKEN', token)
        }
      },
    ],
    afterResponse: [
      (request, options, response) => {
        if (response.status === 401) {
          unauthorizedResponse(request)
        }
      },
    ],
  },
})
