import type { FC } from 'react'
import React from 'react'
import { Box, Container } from '@chakra-ui/react'
import { LoginForm } from './LoginForm'

export const LoginPage: FC = () => (
  <Container mt='200px' centerContent>
    <Box
      width='sm'
      boxShadow='lg'
      p='5'
      rounded='lg'
      bg='base.0'
      borderTopWidth='4px'
      borderTopColor='contrast.200'
    >
      <LoginForm />
    </Box>
  </Container>
)
