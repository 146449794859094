import type { ChangeEvent } from 'react'
import React from 'react'
import { reflect } from '@effector/reflect'
import { InputBase } from './InputBase'
import { $password, changePassword } from '../model'
import { Icon } from '@chakra-ui/react'
import { FaAsterisk } from 'react-icons/fa'

const inputChanged = (event: ChangeEvent<HTMLInputElement>) =>
  event.target.value

export const PasswordInput = reflect({
  view: InputBase,
  bind: {
    value: $password,
    onChange: changePassword.prepend(inputChanged),
    placeholder: 'Password',
    icon: <Icon as={FaAsterisk} color='gray.500' />,
    type: 'password',
    autocomplete: 'current-password',
  },
})
