import React from 'react'
import { Alert, AlertIcon, Text } from '@chakra-ui/react'

export const MessageError = (text: string): JSX.Element => {
  return (
    <Alert status='error' bg='none'>
      <AlertIcon />
      <Text fontWeight='bold' color='gray.600'>
        {text}
      </Text>
    </Alert>
  )
}
