import type { FC, ChangeEvent } from 'react'
import React from 'react'
import { reflect } from '@effector/reflect'
import { Checkbox, Text } from '@chakra-ui/react'
import { $rememberMe, changeRememberMe } from '../model'

type Props = {
  value: boolean
  onChange: () => void
}

const RememberMeCheckboxBase: FC<Props> = ({ value, onChange }: Props) => (
  <Checkbox colorScheme='gray' isChecked={value} onChange={onChange} pl={3}>
    <Text color='gray.500' pl={1}>
      Remember me
    </Text>
  </Checkbox>
)

const inputChanged = (event: ChangeEvent<HTMLInputElement>) =>
  event.target.value

export const RememberMeCheckbox = reflect({
  view: RememberMeCheckboxBase,
  bind: {
    value: $rememberMe,
    onChange: changeRememberMe.prepend(inputChanged),
  },
})
