import type { FC } from 'react'
import React from 'react'
import { Routing } from '~/pages'
import { withProviders } from './providers'
import './index.css'

const App: FC = () => {
  return <Routing />
}

export const AppContainer = withProviders(App)
