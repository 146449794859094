import type { FC } from 'react'
import React from 'react'
import { Center, Spinner as ChakraSpinner } from '@chakra-ui/react'

export const Spinner: FC = () => {
  return (
    <Center w='100vw' h='100vh'>
      <ChakraSpinner color='gray.500' size='xl' />
    </Center>
  )
}
