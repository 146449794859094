import { createEvent, guard } from 'effector'
import { userModel } from '~/entities/user'
import { autoLoginModel } from '~/features/auto-login'

export const init = createEvent<void>()

guard({
  source: init,
  filter: userModel.$isAuthenticated.map((authenticated) => !authenticated),
  target: autoLoginModel.tryAutoLogin,
})
