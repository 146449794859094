import type { FC } from 'react'
import React from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'

// because of syntax ambiguities we have to use `extends` clause here
// eslint-disable-next-line @typescript-eslint/ban-types
export const withChakra = <T extends {}>(Component: FC<T>): FC<T> => {
  const theme = {
    styles: {
      global: {
        'html, body': {
          backgroundColor: '#F7FAFC',
        },
      },
    },
    colors: {
      base: {
        0: 'white',
        50: '#F7FAFC',
        100: '#EDF2F7',
        200: '#E2E8F0',
        300: '#CBD5E0',
        400: '#CBD5E0',
        500: '#1A202C',
      },
      contrast: {
        100: '#EBF8FF',
        200: '#3182CE',
      },
    },
  }

  const globalTheme = extendTheme(theme)

  const Wrapper = (props: T) => (
    <ChakraProvider theme={globalTheme}>
      <Component {...props} />
    </ChakraProvider>
  )
  Wrapper.displayName = `withChakra(${
    Component.displayName || Component.name || 'Component'
  })`
  return Wrapper
}
