import type { FC } from 'react'
import React from 'react'
import { InputGroup, InputLeftElement, Input } from '@chakra-ui/react'

type InputBaseType = {
  value: string
  onChange: () => void
  placeholder: string
  icon: React.ReactNode
  type: string
  autocomplete: string
}

export const InputBase: FC<InputBaseType> = ({
  value,
  onChange,
  placeholder,
  icon,
  type,
  autocomplete,
}: InputBaseType) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents='none'>{icon}</InputLeftElement>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        autoComplete={autocomplete}
        variant='outline'
        focusBorderColor='contrast.200'
        errorBorderColor='#FEB2B2'
        bg='contrast.100'
      />
    </InputGroup>
  )
}
