import type { FC } from 'react'
import React from 'react'
import { useStore } from 'effector-react'
import { Button, Icon } from '@chakra-ui/react'
import { FaSignInAlt } from 'react-icons/fa'
import { login, $isSignining } from '../model'

export const LoginButton: FC = () => {
  const signining = useStore($isSignining)
  return (
    <Button
      onClick={() => login()}
      color='base.0'
      leftIcon={<Icon as={FaSignInAlt} />}
      bg='contrast.200'
      _hover={{ bg: 'contrast.100' }}
      variant='solid'
      isDisabled={signining}
    >
      Sign in
    </Button>
  )
}
